.col-1 {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-flex-basis: calc(99.9999% / 12 * 1 - 16px);
  -ms-flex-preferred-size: calc(99.9999% / 12 * 1 - 16px);
  flex-basis: calc(99.9999% / 12 * 1 - 16px);
  margin: 0 8px;
  max-width: calc(99.9999% / 12 * 1 - 16px);
  min-width: calc(99.9999% / 12 - 16px);
  word-wrap: break-word;
}
.col-1-8 {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-flex-basis: calc(99.9999% / 12 * 1.8 - 16px);
  -ms-flex-preferred-size: calc(99.9999% / 12 * 1.8 - 16px);
  flex-basis: calc(99.9999% / 12 * 1.8 - 16px);
  margin: 0 8px;
  max-width: calc(99.9999% / 12 * 1.8 - 16px);
  min-width: calc(99.9999% / 12 - 16px);
  word-wrap: break-word;
}
.col-2 {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-flex-basis: calc(99.9999% / 12 * 2 - 16px);
  -ms-flex-preferred-size: calc(99.9999% / 12 * 2 - 16px);
  flex-basis: calc(99.9999% / 12 * 2 - 16px);
  margin: 0 8px;
  max-width: calc(99.9999% / 12 * 2 - 16px);
  min-width: calc(99.9999% / 12 - 16px);
  word-wrap: break-word;
  text-align: center;
}
.col-3-8 {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-flex-basis: calc(99.9999% / 12 * 3.8 - 16px);
  -ms-flex-preferred-size: calc(99.9999% / 12 * 3.8 - 16px);
  flex-basis: calc(99.9999% / 12 * 3.8 - 16px);
  margin: 0 8px;
  max-width: calc(99.9999% / 12 * 3.8 - 16px);
  min-width: calc(99.9999% / 12 - 16px);
  word-wrap: break-word;
}
.col-7-4 {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-flex-basis: calc(99.9999% / 12 * 7.4 - 16px);
  -ms-flex-preferred-size: calc(99.9999% / 12 * 7.4 - 16px);
  flex-basis: calc(99.9999% / 12 * 7.4- 16px);
  margin: 0 8px;
  max-width: calc(99.9999% / 12 * 7.4 - 16px);
  min-width: calc(99.9999% / 12 - 16px);
  word-wrap: break-word;
}
.margin-left-auto {
  margin-left: auto;
}
.display-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dynamic-input-container.validation-error > div > div,
.dynamic-select-container.validation-error > div > div,
.dynamic-checkbox-container.validation-error > div,
.dynamic-multiInput-enterArea.validation-error {
  border-color: #bf1650;
}
.dynamic-multiInput-enterArea,
.dynamic-checkbox-container > div {
  border-radius: 3px;
  border-style: solid;
  border-width: 2px;
  box-shadow: none;
}
.dynamic-checkbox-container > div {
  border-color: white;
}
.dynamic-multiInput-enterArea {
  border-color: #dfe1e6;
}
.dynamic-multiInput-textInput-container > div {
  border: 0px;
}
